@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

.error{
	font-size: 14px;
	color: red;
	padding-left: 6px;
	position: relative;
	top: -5px;

}

input:checked.defaultCheckbox::before { 
	opacity: 1;
}

button[disabled]{
	opacity: 0.8;
	cursor: not-allowed;
}
.align-center{
	align-items: center;
}

.languageParent{
	// padding: 20px;
	margin-top: 20px;
	display: flex;
	gap: 30px;
	align-items: flex-start;
	.langSelect{
		padding: 5px 10px;
		border: 1px solid #f1f1f1;
		background-color: white;
		&:hover{
			background-color: rgba(0,0,0,0.04);
			cursor: pointer;
		}

	}
	.Deflanguage{
		padding: 5px 10px;
		border: 1px solid #f1f1f1;
		background-color: white;
	}
}
/* modal UI */

.modal-overlay{
	position: fixed;
	background-color: rgba(0,0,0,0.4);
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 99;
	.modal{
		position: relative;
		background-color: white;
		width: 900px;
		margin: auto;
		margin-top: 10vh;
		padding: 20px;
		border-radius: 10px;
		.close{
			position: absolute;
			right: 10px;
			top: 10px;
			font-size: 25px;
		}
		.languageList{
			ul{
				height: calc(100vh - 400px);
				min-height: 300px;
				overflow-x: auto;

				li{
					display: inline-block;
					width: 200px;
					border: 1px solid transparent;
					padding: 8px 20px;
					border-radius: 25px;
					margin-bottom: 0px;
					font-size: 14px;
					white-space: nowrap;
					margin-right: 5px;
					white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
					&:hover{
						background-color: rgba(0,0,0,0.03);
						cursor: pointer;
					}
				}
				.active{
					background-color: rgb(225, 255, 196);;
					border-color: rgb(15, 86, 15);
					color: rgb(15, 86, 15);
					&:hover{
						background-color: rgb(225, 255, 196);;
					border-color: rgb(15, 86, 15);
					}
				}
			}
		}
		.saveBtn{
			// border: unset;
			// background-color: rgb(75, 9, 208);
			// color: white;
			// padding: 10px 20px;
			// border-radius: 10px;
		}
	}
}

.tabsView{
	margin: 20px 0;
	border-bottom: 1px solid #ccc;
	button{
		padding: 10px 20px;
		display: inline-block;
		background-color: #f1f1f1;
	}
	.active{
		background-color: #3b82f6;
		color: white;
	}
}

.themeHover{
	div{
		transform: scale(1);
		transition: .1s;
		border: 2px solid transparent;
		&:hover{
			box-shadow: 0 0 10px 0 #ccc;
			transition: .1s;
		}
	}
	.active{
		border-color: black;
	}
}
.LangLoader{
	background-color: rgba(0,0,0,0.8);
	color: white;
	height: 100vh;
	width: 100%;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;

}
.knowledge {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// width: 100%;
	// padding: 70px 0;
	// background-color: white;
    // box-shadow: 0 10px 13px 0 #f1f1f1;
    // border-radius: 10px;
  
	.upload-container {
	  margin-bottom: 20px;
	  width: 100%;
  
	  .upload-input {
		width: 100%;
		font-size: 16px;
		border: 1px dashed #ccc;
		border-radius: 5px;
		background-color: #fff;
		height: unset;
		padding: 15px 20px;

		&::file-selector-button{
			background-color: transparent;
			border: unset;
			border-right: 1px solid #ccc;
			padding-right: 10px;
			margin-right: 10px;
		}
		&:hover{
			background-color: #f1f1f1;
			cursor: pointer;
		}
	  }
	}
  
	.text-input {
	  width: 100%;
	  max-width: 600px;
	  padding: 10px;
	  font-size: 16px;
	  border: 1px solid #ccc;
	  border-radius: 5px;
	  box-sizing: border-box;
	}
  
	.submit-button {
	  padding: 10px 20px;
	  font-size: 16px;
	//   width: 100%;
	  border: none;
	  border-radius: 5px;
	  background-color: #007bff;
	  color: white;
	  cursor: pointer;
	  transition: background-color 0.3s;
  
	  &:hover {
		background-color: #0056b3;
	  }
	}
  }
  

  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }
  
  .spin {
	animation: spin 1s linear infinite;
  }

  
.w-SS{
	svg{
		width: 24px;
		font-size: 20px;
	}
}
.emailCopy{
	cursor: pointer;
	&:hover{
		color: #0056b3;
		transform: scale(1.05);
	}
}

