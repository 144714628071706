.chatIn{
    height: calc(100vh - 140px);
    min-height: 500px;
    .chatInputBox{
        border-top: 1px solid #f1f1f1;
        position: relative;
        padding: 10px;
        input{
            border: unset;
            font-size: 14px;
            padding: 10px 15px;
            border-radius: 30px;
            background-color: #f1f1f1;
            padding-right: 40px;
            &:focus{
                background-color: #f1f1f1;
                outline: unset;
            }
        }
        .sendBtn{
            position: absolute;
            border-radius: 30px;
            padding: 8px;
            right: 15px;
            top: 14px;
        }
    }
}
